<template>
  <div>
    <b-container>
      <b-row>
        <b-col/>
        <b-col cols="auto" style="margin-top: 15%" class="text-center">
          <h1 class="text-white mb-5">
            <b-icon-emoji-frown v-if="number === 0" font-scale="5" variant="white"/>
            <span v-if="number === 1" style="font-size: 128px">(._.)</span>
            <span v-if="number === 2" style="font-size: 64px">(╯°□°）╯︵ ┻━┻</span>
            <span v-if="number === 3" style="font-size: 128px">¯\_(ツ)_/¯</span>
          </h1>
          <h5 class="text-white">Upsi... die Seite die du aufrufen möchtest, existiert leider nicht.</h5>
          <h6 class="text-white">Versuche bitte eine andere Seite zu öffnen.</h6>
        </b-col>
        <b-col/>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
  data() {
    return {
      number: 0,
    }
  },
  created() {
    this.number = Math.floor(Math.random() * 4);
  }
}
</script>

<style lang="scss" scoped>
</style>
